<template>
  <v-app id="roster_logs">
    <!-- roster names -->
    <v-container id="rname_container">
      <v-row no-gutters>
        <v-col cols="12" md="12" class="rname_expansion">
          <!-- Expansion Panels -->
          <v-expansion-panels>
            <!-- names Expansion Panel -->
            <v-expansion-panel class="mb-3">
              <v-expansion-panel-header
                class="rname_expansion white--text rounded-b-0"
              >
                ROSTER NAMES
                <template v-slot:actions>
                  <v-icon color="white" x-large> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="cmpbkinside">
                <!-- toolbar -->
                <v-toolbar flat class="roster-toolbar">
                  <v-toolbar-title>Roster Names</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="openDialog_rname"
                    >New Name</v-btn
                  >
                </v-toolbar>
                <!-- <hr /> -->

                <!-- NAMES LIST -->
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-list class="list-rname">
                        <hr />
                        <v-list-item
                          class="single-name d-flex flex-row justify-space-between"
                          v-for="(item_rname, index) in items_rname"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item_rname.name
                            }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action class="d-flex flex-row">
                            <v-icon
                              class="mr-3"
                              small
                              @click="editItem_rname(index)"
                              >$edit</v-icon
                            >
                            <v-icon small @click="deleteItem_rname(index)"
                              >$del</v-icon
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- END names Expansion Panel -->

            <!-- Participants expansion panel -->
            <v-expansion-panel class="mb-3">
              <v-expansion-panel-header
                class="part_expansion white--text rounded-b-0"
              >
                COORDINATING/PARTICIPATING AGENCIES OR OFFICERS
                <template v-slot:actions>
                  <v-icon color="white" x-large> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="cmpbkinside">
                <!-- toolbar -->
                <v-toolbar flat class="roster-toolbar">
                  <v-toolbar-title
                    >Coordinating/Participating Agencies or
                    Officers</v-toolbar-title
                  >
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="openDialog_rpart"
                    >New Participant</v-btn
                  >
                </v-toolbar>
                <!-- <hr /> -->

                <!-- Particpants LIST -->
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-list class="list-rpart">
                        <hr />
                        <v-list-item
                          class="single-part d-flex flex-row justify-space-between"
                          v-for="(item_rpart, index) in items_rpart"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item_rpart.participant
                            }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action class="d-flex flex-row">
                            <v-icon
                              class="mr-3"
                              small
                              @click="editItem_rpart(index)"
                              >$edit</v-icon
                            >
                            <v-icon small @click="deleteItem_rpart(index)"
                              >$del</v-icon
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- END participants expansion panel -->

            <!-- Assignments expansion panel -->
            <v-expansion-panel class="mb-3">
              <v-expansion-panel-header
                class="rassignment_expansion white--text rounded-b-0"
              >
                ROSTER ASSIGNMENTS
                <template v-slot:actions>
                  <v-icon color="white" x-large> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="cmpbkinside">
                <!-- toolbar -->
                <v-toolbar flat class="roster-toolbar">
                  <v-toolbar-title>Roster Assignments</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="openDialog_rassignment"
                    >New Assignment</v-btn
                  >
                </v-toolbar>
                <!-- <hr /> -->

                <!-- AssignmentS LIST -->
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-list class="list-rassignment">
                        <hr />
                        <v-list-item
                          class="single-assignment d-flex flex-row justify-space-between"
                          v-for="(item_rassignment, index) in items_rassignment"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item_rassignment.assignment
                            }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action class="d-flex flex-row">
                            <v-icon
                              class="mr-3"
                              small
                              @click="editItem_rassignment(index)"
                              >$edit</v-icon
                            >
                            <v-icon small @click="deleteItem_rassignment(index)"
                              >$del</v-icon
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- END Assignments expansion panel -->

            <!-- Teams Expansion panel -->
            <v-expansion-panel>
              <v-expansion-panel-header
                class="rteam_expansion white--text rounded-b-0"
              >
                ROSTER TEAMS
                <template v-slot:actions>
                  <v-icon color="white" x-large> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="cmpbkinside">
                <!-- toolbar -->
                <v-toolbar flat class="roster-toolbar">
                  <v-toolbar-title>Roster Teams</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="openDialog_rteam"
                    >New team</v-btn
                  >
                </v-toolbar>
                <!-- <hr /> -->

                <!-- teamS LIST -->
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-list class="list-rteam">
                        <hr />
                        <v-list-item
                          class="single-team d-flex flex-row justify-space-between"
                          v-for="(item_rteam, index) in items_rteam"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item_rteam.team
                            }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action class="d-flex flex-row">
                            <v-icon
                              class="mr-3"
                              small
                              @click="editItem_rteam(index)"
                              >$edit</v-icon
                            >
                            <v-icon small @click="deleteItem_rteam(index)"
                              >$del</v-icon
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- END Teams Expansion panel -->
          </v-expansion-panels>
          <!-- end Roster Expansion Panels -->
        </v-col>
      </v-row>

      <!-- New Name Dialog -->

      <v-dialog v-model="openDialog_rname_show" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle_rname }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem_rname.name"
                    label="Name"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="rnameCloseDialog"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveItem_rname"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END New Name Dialog -->

      <!-- New Participant Dialog -->

      <v-dialog v-model="openDialog_rpart_show" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle_rpart }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem_rpart.participant"
                    label="Participant"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="rpartCloseDialog"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveItem_rpart"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END New Participant Dialog -->

      <!-- New Assignment Dialog -->

      <v-dialog v-model="openDialog_rassignment_show" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle_rassignment }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem_rassignment.assignment"
                    label="assignment"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="rassignmentCloseDialog"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveItem_rassignment"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END New Assignment Dialog -->

      <!-- New Team Dialog -->
      <v-dialog v-model="openDialog_rteam_show" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle_rteam }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem_rteam.team"
                    label="team"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="rteamCloseDialog"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveItem_rteam"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- END New Team Dialog -->
    </v-container>
  </v-app>
</template>

<style lang="scss" scoped>
/* 0 media q */
@media (min-width: 0px) {
  #roster_logs {
    width: 96%;
    margin: 0 auto;
    margin-top: 67px;
  }

  #roster_logs:deep.theme--light.v-application {
    background: none;
  }

  #roster_logs:deep .v-application--wrap {
    /* min-height: 100vh !important; */
    min-height: auto;
  }

  /* roster logs names */
  .rname_expansion {
    height: 48px;
    width: 100%;
    margin: 0 auto;
    background-color: #708e92;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  .rname_expansion p {
    font-size: 0.9375rem;
  }

  .roster-toolbar {
    height: 30px !important;
  }

  .single-name {
    border-bottom: 2px solid #0000001f;
  }

  .list-rname hr {
    margin-top: 20px;
    border-color: #0000001f;
  }

  /* End roster names */

  /* Roster Assignments */
  .rassignment_expansion,
  .part_expansion {
    height: 48px;
    width: 100%;
    margin: 0 auto;
    background-color: #708e92;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  .rassignment_expansion p,
  .part_expansion p {
    font-size: 0.9375rem;
  }

  .single-assignment,
  .single-part {
    border-bottom: 2px solid #0000001f;
  }

  .list-rassignment hr,
  .list-rpart hr {
    margin-top: 20px;
    border-color: #0000001f;
  }

  /* End Roster assignments */

  /* Roster Teams */
  .rteam_expansion {
    height: 48px;
    width: 100%;
    margin: 0 auto;
    background-color: #708e92;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  .rteam_expansion p {
    font-size: 0.9375rem;
  }

  .single-team {
    border-bottom: 2px solid #0000001f;
  }

  .list-rteam hr {
    margin-top: 20px;
    border-color: #0000001f;
  }

  /* ENDRoster Teams */
}

/* 768 media q */
@media (min-width: 768px) {
}

/* 1025 media q */
@media (min-width: 1025px) {
}
</style>

<script>
import { bus_common } from "../mixins/bus_common";

export default {
  name: "roster_logs",
  components: {},
  mixins: [bus_common],
  data() {
    return {
      openDialog_rname_show: false,
      openDialog_rpart_show: false,
      openDialog_rassignment_show: false,
      openDialog_rteam_show: false,
      items_rname: [
        { name: "Autry" },
        { name: "Baehr" },
        { name: "Butler" },
        { name: "Chang" },
        { name: "Christensen, B" },
        { name: "Clifford" },
        { name: "Donigan" },
        { name: "Dougherty" },
        { name: "Duralde" },
        { name: "Espinoza, A" },
        { name: "Hakin" },
        { name: "Hanna" },
        { name: "Hendrix" },
        { name: "Hogan" },
        { name: "Hoyt" },
        { name: "Kent" },
        { name: "Kerfoot" },
        { name: "Lancaster" },
        { name: "Lopey" },
        { name: "Mathiowetz" },
        { name: "Meseberg" },
        { name: "Perham, J" },
        { name: "Robles" },
        { name: "Rousseau" },
        { name: "Schaur" },
        { name: "Schmidt" },
        { name: "Smith" },
        { name: "Sotelo" },
        { name: "Struffert" },
        { name: "Tom" },
        { name: "Tone" },
        { name: "Waasdorp" },
        { name: "Williams" },
        { name: "Ziese" },
      ],

      items_rpart: [
        { participant: "SPD" },
        { participant: "WCSO" },
        { participant: "Airport PD" },
        { participant: "School PD" },
        { participant: "Lyon County SO" },
        { participant: "Carson City SO" },
        { participant: "Douglas County SO" },
        { participant: "Secret Service" },
        { participant: "CINT" },
      ],
      items_rassignment: [
        { assignment: "TAC1" },
        { assignment: "TAC2" },
        { assignment: "TAC3" },
        { assignment: "ODC" },
        { assignment: "C2" },
        { assignment: "Sick" },
      ],
      items_rteam: [
        { team: "RED CELL" },
        { team: "GREEN CELL" },
        { team: "BLUE CELL" },
        { team: "REMSA TEMS" },
        { team: "NONE" },
      ],
      editedIndex_rname: -1,
      editedItem_rname: {
        name: "",
      },
      defaultItem_rname: {
        name: "",
      },

      editedIndex_rpart: -1,
      editedItem_rpart: {
        participant: "",
      },
      defaultItem_rpart: {
        participant: "",
      },

      editedIndex_rassignment: -1,
      editedItem_rassignment: {
        assignment: "",
      },
      defaultItem_rassignment: {
        assignment: "",
      },
      editedIndex_rteam: -1,
      editedItem_rteam: {
        team: "",
      },
      defaultItem_rteam: {
        team: "",
      },
    };
  },
  computed: {
    formTitle_rname() {
      return this.editedIndex_rname === -1
        ? "New Roster Name"
        : "Edit Roster Name";
    },
    formTitle_rpart() {
      return this.editedIndex_rpart === -1
        ? "New Participant Name"
        : "Edit Participant Name";
    },
    formTitle_rassignment() {
      return this.editedIndex_rassignment === -1
        ? "New Roster assignment"
        : "Edit Roster assignment";
    },
    formTitle_rteam() {
      return this.editedIndex_rteam === -1
        ? "New Roster team"
        : "Edit Roster team";
    },
  },

  /* METHODS */
  methods: {
    /* Roster names */
    openDialog_rname() {
      this.openDialog_rname_show = true;
      this.editedItem_rname = this.mno(this.defaultItem_rname);
    },
    editItem_rname(index) {
      this.editedIndex_rname = index;
      this.editedItem_rname = this.mno(this.items_rname[index]);
      this.openDialog_rname_show = true;
    },
    deleteItem_rname(index) {
      this.items_rname.splice(index, 1);
    },
    rnameCloseDialog() {
      this.openDialog_rname_show = false;
      this.$nextTick(() => {
        this.editedItem_rname = this.mno(this.defaultItem_rname);
        this.editedIndex_rname = -1;
      });
    },
    saveItem_rname() {
      console.log(this.editedItem_rname.name);
      if (this.editedIndex_rname > -1) {
        this.items_rname[this.editedIndex_rname] = this.mno(
          this.editedItem_rname
        );
      } else {
        this.items_rname.push(this.editedItem_rname);
      }
      this.rnameCloseDialog();
    },

    /* Roster Assignment */
    openDialog_rassignment() {
      this.openDialog_rassignment_show = true;
      this.editedItem_rassignment = this.mno(this.defaultItem_rassignment);
    },
    editItem_rassignment(index) {
      this.editedIndex_rassignment = index;
      this.editedItem_rassignment = this.mno(this.items_rassignment[index]);
      this.openDialog_rassignment_show = true;
    },
    deleteItem_rassignment(index) {
      this.items_rassignment.splice(index, 1);
    },
    rassignmentCloseDialog() {
      this.openDialog_rassignment_show = false;
      this.$nextTick(() => {
        this.editedItem_rassignment = this.mno(this.defaultItem_rassignment);
        this.editedIndex_rassignment = -1;
      });
    },
    saveItem_rassignment() {
      console.log(this.editedItem_rassignment.assignment);
      if (this.editedIndex_rassignment > -1) {
        this.items_rassignment[this.editedIndex_rassignment] = this.mno(
          this.editedItem_rassignment
        );
      } else {
        this.items_rassignment.push(this.editedItem_rassignment);
      }
      this.rassignmentCloseDialog();
    },

    // END roster assignments

    // Participant Assignment
    openDialog_rpart() {
      this.openDialog_rpart_show = true;
      this.editedItem_rpart = this.mno(this.defaultItem_rpart);
    },
    editItem_rpart(index) {
      this.editedIndex_rpart = index;
      this.editedItem_rpart = this.mno(this.items_rpart[index]);
      this.openDialog_rpart_show = true;
    },
    deleteItem_rpart(index) {
      this.items_rpart.splice(index, 1);
    },
    rpartCloseDialog() {
      this.openDialog_rpart_show = false;
      this.$nextTick(() => {
        this.editedItem_rpart = this.mno(this.defaultItem_rpart);
        this.editedIndex_rpart = -1;
      });
    },
    saveItem_rpart() {
      console.log(this.editedItem_rpart.participant);
      if (this.editedIndex_rpart > -1) {
        this.items_rpart[this.editedIndex_rpart] = this.mno(
          this.editedItem_rpart
        );
      } else {
        this.items_rpart.push(this.editedItem_rpart);
      }
      this.rpartCloseDialog();
    },

    // END Participant Assignment

    // TEAM DIALOG
    openDialog_rteam() {
      this.openDialog_rteam_show = true;
      this.editedItem_rteam = this.mno(this.defaultItem_rteam);
    },
    editItem_rteam(index) {
      this.editedIndex_rteam = index;
      this.editedItem_rteam = this.mno(this.items_rteam[index]);
      this.openDialog_rteam_show = true;
    },
    deleteItem_rteam(index) {
      this.items_rteam.splice(index, 1);
    },
    rteamCloseDialog() {
      this.openDialog_rteam_show = false;
      this.$nextTick(() => {
        this.editedItem_rteam = this.mno(this.defaultItem_rteam);
        this.editedIndex_rteam = -1;
      });
    },
    saveItem_rteam() {
      console.log(this.editedItem_rteam.team);
      if (this.editedIndex_rteam > -1) {
        this.items_rteam[this.editedIndex_rteam] = this.mno(
          this.editedItem_rteam
        );
      } else {
        this.items_rteam.push(this.editedItem_rteam);
      }
      this.rteamCloseDialog();
    },
  },
};
</script>
